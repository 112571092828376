<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="searchtime" style="margin-right: 0.4rem">
        <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('lang.to')"
          :start-placeholder="$t('lang.Startdate')"
          :end-placeholder="$t('lang.Enddate')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="picker"
        >
        </el-date-picker>
      </div>
      <div class="query">
        <div class="inputname questiontwo">
          <input v-model="parsm.keyword" :placeholder="$t('lang.Pleasebillnumber')" />
        </div>
        <div class="searchbtn">
          <button @click="sear">
            <p style="color: #b2b2b2">{{ $t("lang.search") }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column :label="$t('lang.paymentdays')" align="center" width="150">
					<template slot-scope="scope">
					  <span>{{scope.row.startTime | times}}</span>
					</template>
        </el-table-column>
        <el-table-column prop="email" align="center" :label="$t('lang.accountnumber')" width="220">
        </el-table-column>
        <el-table-column
          :label="$t('lang.billnumber')"
          prop="sn"
          align="center"
					width="220"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.Thebilltype')"
          align="center"
					width="150"
        >
          <template #default="scope">
            <span v-if="scope.row.type === 0">{{$t('lang.Flowbill')}}</span>
            <span v-if="scope.row.type === 1">{{$t('lang.Storebills')}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.consumptiontime')" align="center" width="440">
					<template slot-scope="scope">
					  <span>{{scope.row.startTime}}——</span>
					  <span>{{scope.row.endTime}}</span>
					</template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.unitprice')"
          align="center"
					width="130"
        >
          <template #default="scope">
            <span>{{ scope.row.unitPrice }}RMB/GB</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.dosage')" align="center" width="130">
          <template #default="scope">
            <span>{{ scope.row.amount |amoutsa }}GB</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.balance')" align="center" width="100">
        	<template slot-scope="scope">
        		<span>{{scope.row.money |monsa}}</span>
        	</template>
        </el-table-column>
        <el-table-column :label="$t('lang.paymentamount')" align="center" width="180">
        	<template slot-scope="scope">
        		<span>{{scope.row.payPrice |monsa}}</span>
        	</template>
        </el-table-column>
        <el-table-column :label="$t('lang.Owetheamount')" align="center" width="180">
        	<template slot-scope="scope">
        		<span>{{scope.row.arrearsPrice |monsa}}</span>
        	</template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="120"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content="seenFreetext"
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="handleEdit(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/xiangqing.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/xiangqing2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="display: flex; align-items: center"
        v-if="tableData.length > 0"
      >
        <div class="allNum">{{ $t("lang.total") }}: {{ settall }}</div>
        <el-pagination
          background
          :current-page="parsm.page"
          v-if="tableData.length > 0"
          layout="prev, pager, next"
          :total="settall"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as workmsg from "@/api/manage.js";
export default {
  data() {
    return {
      time: "",
      value1: "",
      tableData: [],
      settall: 0,
      parsm: {
        page: 1,
        size: 10,
        keyword: "",
        type: 0,
				etime:'',
				stime:'',
				time:'',
				email:''
      },
    };
  },
	computed: {
		seenFreetext() {
			return this.$t("lang.details")
		}
	},
	created() {
		if(this.$route.query.time){
			this.parsm.time=this.$route.query.time
			this.parsm.email=this.$route.query.email
		}
		this.getList();
	},
	filters:{
		times(val){
			let skr=''
			if(val){
				let sk=val.split(' ')
				let skk=sk[0].split('-')
				skr=skk[0]+'-'+skk[1]
			}
			return skr
		},
		amoutsa(val){
			if(val){
				return val.toFixed(4)
			}else{
				return val
			}
		},
		monsa(val){
			if(val){
				return val.toFixed(2)
			}else{
				return val
			}
		}
	},
  methods: {
    // 搜索
    sear() {
      this.parsm.page = 1;
      this.getList();
    },
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
      }
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.stime = e[0];
        this.parsm.etime = e[1];
      } else {
        this.parsm.stime = "";
        this.parsm.etime = "";
      }
      this.getList();
    },
    //获取流量账单列表
    async getList() {
      let res = await workmsg.billListApi(this.parsm);
      //console.log(res);
      if (res.data) {
        res.data.content.forEach((item) => {
          item.flag1 = 0;
        });
        this.tableData = res.data.content;
        this.settall = res.data.totalElements;
      }
    },
    //详情
    handleEdit(row) {
      this.$router.push({
        path: "billdetail",
        query: {email:this.parsm.email,sn: row.sn },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.messige {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  background: red;
  top: 0.05rem;
  left: 0.2rem;
  border-radius: 50%;
}
.texts {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
